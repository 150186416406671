<template>
	<div class="page container">
		<v2-back-btn :route="`/browse-courses`" />
		<div class="page__info">
			<div class="page__info--title">
				Checking payment status
			</div>
		</div>

		<p>Waiting…</p>
		<V2Button
			size="xl"
			fit
			to="/browse-courses"
		>
			Cancel
		</V2Button>

	</div>
</template>

<script>
	import V2Button           from '@/components/v2/ui/V2Button';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';
	import api         from '@/services/api';

	export default {
		metaInfo: {
			title: 'Checking payment status'
		},
		layout: 'v2default',
		components: {
			V2Button,
			V2BackBtn
		},
		data: () => ({
			enrolment: undefined,
			timer: undefined
		}),
		computed: {
			getEnrolmentId () {
				return this.$route.query.id;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/browse-courses',
						text: 'Find a course'
					},
					{
						path: '/browse-courses/checkout-complete',
						text: 'Checking payment status'
					}
				].filter(Boolean);
			}
		},
		watch: {
			enrolment (newVal) {
				console.log('Enrolment changed');
				if (!newVal?.activated) {
					console.log('Enrolment not activated yet though');
					return false;
				}
				console.log('Enrolment activated, stopping timer');
				clearTimeout(this.timer);
				this.onActivated();
			}
		},
		created () {
			this.init();
		},
		beforeDestroy () {
			if (!this.timer) {
				return;
			}
			clearTimeout(this.timer);
		},
		methods: {
			async runTimer () {
				const app = this;
				const enrolment = await api.courses.getEnrolmentById({
					enrolmentId: this.getEnrolmentId
				});
				if (enrolment?.activated) {
					this.enrolment = enrolment;
					return true;
				}
				console.log(enrolment);
				this.timer = setTimeout(() => {
					console.log('Running timer again');
					app.runTimer();
				}, 3000);
			},
			async init () {
				if (!this.getEnrolmentId) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
				await this.runTimer();
			},
			async onActivated () {
				await this.$router.push('/my-courses');
				this.$store.commit('ui/showNotification', {
					notification: 'Your course has been added'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

p {
  @include font(18px, $c-dark-navy, 500, 24px);
  margin:0 0 rem(32);
}
.btn {
  float:left;
  width:auto;
  margin:0 auto 0 0;
}

</style>
